import React from 'react';

const Background = () => (
  <div
    aria-hidden="true"
    style={{
      minHeight: '132vh',
      maxHeight: '1480px',
      position: 'absolute',
      top: 0,
      right: 0,
      left: 0,
    }}
  >
    {/* BG MOUNTAINS */}
    <svg
      viewBox="0 0 1303 1480"
      fill="none"
      preserveAspectRatio="none"
      style={{
        position: 'absolute',
        top: 0,
        right: 0,
        left: 0,
        width: '100%',
        height: '100%',
      }}
    >
      <path d="M1302 0H0V1479L1302 1480V0Z" fill="#756B8A" />
      <path
        d="M1302 355L1302 1480L8.67844e-05 1479L0 234C22.2093 220.921 59.5108 204.995 73.44 181.92C78.93 172.82 86.27 165.29 93.8 157.92C100.906 151.003 111.237 153.858 115.85 161.95C119.85 169.84 125.59 176.46 130.99 183.35C136.661 190.667 141.111 198.853 144.17 207.59C147.06 215.68 150.09 223.73 153.09 231.78C160.219 250.946 177.808 264.014 190.28 279.61C200.142 291.512 200.684 307.577 210.54 319.07C231.433 344.373 236.535 383.932 276.64 382.88C303.142 369.419 329.089 384.253 354.42 392C375.07 397.93 393.89 407.8 412.33 418.69C426.034 426.778 439.265 435.637 452.29 444.77C481.29 465.07 512.86 479.77 547.38 487.83C571.54 493.5 595.68 499.25 619.89 504.66C632.48 507.48 643.79 513.33 655.32 518.66C666.08 523.66 663.44 523.51 673.39 517.72C687.94 509.24 700.39 498.38 712.16 486.29C742.766 454.819 771.153 421.117 797.28 385.85C817.482 358.493 844.254 332.882 879.65 329.09C895.18 327.42 910.65 326.5 926.28 326.09C960.04 325.327 990.576 309.155 1021.72 297.26C1068.76 279.303 1119.09 300.96 1164.66 314.39L1302 355Z"
        fill="url(#mountain_radial)"
      />
      <defs>
        <radialGradient
          id="mountain_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(581 -64) rotate(43.1687) scale(641.674 630.068)"
        >
          <stop stopColor="#742BF4" />
          <stop offset="1" stopColor="#28242F" />
        </radialGradient>
      </defs>
    </svg>

    {/* BG MOON */}
    <svg
      viewBox="0 0 294 295"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        position: 'absolute',
        top: '6%',
        right: '10%',
        width: '18%',
        minWidth: '100px',
        maxWidth: '294px',
      }}
    >
      <path
        d="M147 294.5C228.186 294.5 294 228.686 294 147.5C294 66.3141 228.186 0.5 147 0.5C65.8141 0.5 0 66.3141 0 147.5C0 228.686 65.8141 294.5 147 294.5Z"
        fill="url(#moon_linear)"
      />
      <defs>
        <linearGradient
          id="moon_linear"
          x1="147"
          y1="148"
          x2="147"
          y2="234"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F6DEC0" />
          <stop offset="1" stopColor="#FBC0DC" />
        </linearGradient>
      </defs>
    </svg>

    {/* BG RISES */}
    <svg
      viewBox="0 0 1303 1480"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
      style={{
        position: 'absolute',
        top: 0,
        right: 0,
        left: 0,
        width: '100%',
        height: '100%',
      }}
    >
      <path
        d="M0 115.5C12.0703 119.868 20.6562 133.503 33.7397 137.76C47.5497 142.25 56.3899 151.48 60.6299 165.24C66.1789 183.238 74.4157 193.665 91.0801 202.65C105.139 210.215 110.541 224.99 123.07 233.47C129.933 238.407 135.752 244.654 140.19 251.85C147.19 262.69 155.98 271.72 167.42 277.99C183.264 286.681 191.595 301.836 198.089 317.93C206.838 339.703 245.674 355.881 262.989 371.94C276.677 384.552 293.164 378.775 308.359 386.52C321.179 393.04 332.889 401.33 344.159 410.15C365.159 426.59 388.87 437.79 413.1 448.61C444.33 462.53 474.951 477.91 504.691 494.91C521.665 504.904 534.036 518.109 555.001 520.16C613.377 526.673 667.402 555.04 722.001 575.02C764.055 583.341 794.459 608.269 832.381 625.07C857.006 635.969 884.573 634.637 910.801 635.65C935.441 636.65 960.081 637.02 984.721 637.65C1026.27 638.8 1066.46 654.744 1105.1 668.97C1155.5 687.508 1210.89 690.386 1262.22 706.52C1274.87 710.503 1290.11 707.743 1302 713V1480L0 1479.5V115.5Z"
        fill="url(#rises_0_linear)"
      />
      <path
        d="M1302 212V1480L0 1479.54V736.54C211 614.04 335.5 688.54 604.32 546.98C639.482 532.749 667.175 521.403 705.6 522.84C726.74 523.71 746.94 519.14 767.16 513.77C809.81 502.45 847.72 481.9 883.16 456.12C899.55 444.2 917.02 434.02 936.45 427.68C943.185 425.645 948.836 420.71 951.76 414.31C959.291 398.738 973.706 379.292 991.81 375.7C995.228 375.045 998.266 372.918 1000.05 369.93C1005.4 362.042 1012.84 356.436 1020.47 350.93C1026.54 346.156 1032.33 340.665 1037.94 335.37C1051.57 322.537 1066.66 328.488 1082.98 325.16C1115.2 318.063 1126.93 288.26 1152.77 273.32C1165.07 266.451 1179.38 268.808 1191.59 257.68C1207.09 243.883 1225.61 238.534 1243.85 229.95C1262.15 220.246 1283.71 221.84 1302 212Z"
        fill="url(#rises_1_linear)"
      />
      <path
        d="M1302 367V1480L0 1479V584.5C0.0418311 584.537 0.0890381 584.567 0.14 584.59C0.55 584.87 0.95 585.14 1.36 585.4L2 585.83C188.01 708.452 422.979 681.075 609.99 577.5C616.46 571.08 678.26 564.5 692.68 563.8C709.439 563.8 726.208 563.911 742.97 563.89C774.499 563.89 806.02 563.51 837.53 564.99C869.718 566.488 901.194 560.033 933.07 557.1C955.42 555.1 977.61 551.59 1000 549.89C1012.24 548.861 1024.31 546.438 1036 542.67C1066.22 533.094 1095.71 521.367 1124.25 507.58C1143.08 498.58 1159.99 486.81 1176.57 474.36C1200.74 456.188 1226.77 437.679 1246.6 414.58C1257.86 401.42 1271.19 390.58 1284.75 379.99C1290.52 375.488 1296.39 371.698 1302 367Z"
        fill="url(#rises_2_linear)"
      />
      <path
        d="M1302 772.5V1480L0 1479.5V478.5C13.8097 478.5 26.8755 485.4 40.4 487.41C59.367 489.963 77.7856 495.615 94.92 504.14C121.803 517.581 148.329 531.185 178.13 536.89C207.923 542.387 237.858 549.214 268.34 549.06C286.661 548.804 304.944 550.82 322.77 555.06C331.42 557.18 340.31 558.06 349.18 559.01C413.112 565.607 470.11 593.881 525.56 624.4C539.79 632.181 558.166 643.128 573.93 646.96C847.54 706.339 998.189 857.797 1302 772.5Z"
        fill="url(#rises_3_linear)"
      />
      <path
        d="M1302 744V1480L0 1479V495C14.8384 496.855 29.5048 502.042 42.4 509.5C84.0727 533.616 126.115 555.3 171.59 571.65C194 579.65 217.3 584.06 239.81 591.3C257.76 597.07 276.31 597.88 294.63 600.7C312.53 603.46 330.48 605.93 348.29 609.24C365.29 612.39 382.29 615.59 399.14 619.24C409.14 621.44 419.05 623.8 428.92 626.44C445.818 630.818 462.472 636.092 478.81 642.24C492.667 647.555 505.289 651.315 519.96 654.09C532.71 656.53 545.62 655.98 558.49 655.94C562.933 655.923 567.371 656.589 571.62 657.89C577.23 659.21 582.35 661.89 587.73 663.81C598.19 667.59 608.59 671.58 619.41 674.26C631.89 677.34 644.31 680.55 657.41 680.03C669.482 679.547 681.537 681.827 693.6 680.84C702.91 680.07 712.3 680.3 721.53 678.42C726.03 677.5 730.68 677.96 735.27 677.21C743.274 675.908 751.736 674.495 759.85 674.27C775.85 673.73 791.93 672.87 807.99 673.27C826.305 674.787 844.657 680.22 862.76 683.36C877.862 685.971 893.079 685.509 908.26 686.85C919.1 687.85 929.74 686.27 940.48 685.93C948.081 685.533 955.658 684.752 963.18 683.59C971.09 682.54 979.04 682.71 986.92 682.26C996.778 681.613 1006.66 682.974 1015.98 686.26C1023.45 688.98 1031.03 691.37 1038.58 693.77C1057.52 699.77 1076.1 706.85 1094.82 713.49H1094.91C1100.51 715.456 1106.3 716.846 1112.19 717.64L1302 744Z"
        fill="url(#rises_4_linear)"
      />
      <path
        d="M1302 761V1480L0 1479V1016C5.53438 1011.73 11.0027 1006.48 17.25 1003.22C46.4309 988.528 71.8578 967.583 98.94 949.55C121.418 934.57 144.887 921.015 167.52 906.23C186.871 893.607 206.273 881.22 227.31 871.54C246.903 862.4 266.539 853.288 286.19 844.27C309.292 833.598 332.999 824.289 357.19 816.39C373.222 811.444 390.212 809.046 406.92 807.9C417.54 807.18 428.18 805.9 438.76 806.47C456.858 807.522 475.1 806.005 493.15 807.26C505.64 808.55 518.15 808.61 530.72 809.02C540.59 809.34 550.47 808.92 560.34 809.14C567.05 809.29 573.69 808.46 580.34 807.88C593.29 806.74 606.16 804.7 618.96 802.33C630.7 800.15 642.39 797.7 653.56 793.44C673.853 785.633 694.047 777.487 714.14 769C732.079 761.577 750.445 755.232 769.14 750C785.254 745.371 801.778 742.366 818.42 740.45C844.422 737.231 870.728 739.267 896.69 736.72C906.47 735.72 916.36 736.33 926.22 735.83C943.04 734.98 959.91 735.56 976.77 734.83C986.44 734.39 996.1 733.12 1005.82 733.69C1015.01 734.034 1025.51 732.746 1034.77 732.09C1044.72 731.38 1054.77 730.26 1064.77 730.82C1073.09 731.281 1083.02 729.976 1091.36 729.09C1099.36 728.23 1107.53 728.74 1115.64 728.27C1146.49 726.482 1177.2 733.55 1207.25 741.29C1226.9 746.29 1246.73 750.72 1266.45 755.49C1276.93 758.02 1287.45 760.49 1298.2 761.37C1299.4 761.467 1300.92 761.639 1302 761Z"
        fill="url(#rises_5_linear)"
      />
      <path
        d="M1302 868V1480L0 1479.5V1177C108.149 1167.8 224.724 1154.6 323.24 1106.86C418.88 1060.52 496.24 983.599 599.54 967.449C654.789 958.769 701.786 937.062 753.28 917.319C806.246 896.956 860.87 877.315 916.86 866.939C1034.79 844.528 1183.33 837.845 1301.51 867.88C1301.68 867.913 1301.84 867.946 1302 868Z"
        fill="url(#rises_6_radial)"
      />
      <path
        d="M1302 868V1480L0 1479.5V1177C108.149 1167.8 224.724 1154.6 323.24 1106.86C418.88 1060.52 496.24 983.599 599.54 967.449C654.789 958.769 701.786 937.062 753.28 917.319C806.246 896.956 860.87 877.315 916.86 866.939C1034.79 844.528 1183.33 837.845 1301.51 867.88C1301.68 867.913 1301.84 867.946 1302 868Z"
        fill="url(#rises_7_radial)"
      />
      <defs>
        <linearGradient
          id="rises_0_linear"
          x1="241.5"
          y1="250"
          x2="143"
          y2="479"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2D343F" />
          <stop offset="1" stopColor="#12132E" />
        </linearGradient>
        <linearGradient
          id="rises_1_linear"
          x1="1068.5"
          y1="303"
          x2="1121"
          y2="467.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3F2D3C" />
          <stop offset="1" stopColor="#0C1329" />
        </linearGradient>
        <linearGradient
          id="rises_2_linear"
          x1="1070.5"
          y1="381"
          x2="1096.5"
          y2="640.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#493842" />
          <stop offset="1" stopColor="#211731" />
        </linearGradient>
        <linearGradient
          id="rises_3_linear"
          x1="410"
          y1="502"
          x2="382"
          y2="643"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#48254E" />
          <stop offset="1" stopColor="#1B0F36" />
        </linearGradient>
        <linearGradient
          id="rises_4_linear"
          x1="378.5"
          y1="480"
          x2="350"
          y2="770.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#393255" />
          <stop offset="1" stopColor="#231E1D" />
        </linearGradient>
        <linearGradient
          id="rises_5_linear"
          x1="651"
          y1="727.99"
          x2="698"
          y2="907.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#322A40" />
          <stop offset="1" stopColor="#28242F" />
        </linearGradient>
        <radialGradient
          id="rises_6_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(1010.5 656.869) rotate(95.6268) scale(611.949 461.41)"
        >
          <stop stopColor="#FBC0DC" />
          <stop offset="1" stopColor="#8F9AC2" />
        </radialGradient>
        <radialGradient
          id="rises_7_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(191 1041) rotate(53.1559) scale(221.8 176.531)"
        >
          <stop stopColor="#FF8541" stopOpacity="0.48" />
          <stop offset="1" stopColor="#BB5C8D" stopOpacity="0" />
        </radialGradient>
      </defs>
    </svg>

    {/* BG TENT */}
    <svg
      viewBox="0 0 200 231"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        position: 'absolute',
        bottom: '25%',
        left: '7%',
        width: '12%',
        minWidth: '153px',
        maxWidth: '231px',
      }}
    >
      <ellipse
        opacity="0.3"
        cx="92.0889"
        cy="169.787"
        rx="63.8846"
        ry="50.2575"
        transform="rotate(-10.3226 92.0889 169.787)"
        fill="url(#campsite_0_radial)"
      />
      <path
        d="M89 180.55L99 184.05L105.5 185.55V179.55L96.5 177.55L105.5 174.55L101.5 170.05L85 173.05L74.5 176.05L67 186.55H74.5L89 180.55Z"
        fill="url(#campsite_1_linear)"
      />
      <path
        d="M95.1663 156.734C97.6893 158.496 98.2145 160.87 98.3435 163.945C98.5865 169.737 93.4735 175.918 87.4205 175.737C82.2371 175.581 79.0825 171.338 77.7369 166.776C76.015 160.938 77.9874 151.803 81.54 146.868C83.3874 144.302 87.6273 141.528 87.6273 141.528C87.6273 141.528 86.1714 146.691 86.9347 149.869C88.4788 156.299 91.0721 153.875 95.1663 156.734Z"
        fill="url(#campsite_2_linear)"
      />
      <path
        d="M82.5445 156.2C80.4476 157.227 79.7895 158.94 79.3529 161.222C78.5303 165.521 81.7035 170.725 86.2877 171.283C90.2133 171.762 93.0603 168.954 94.5785 165.701C96.5213 161.537 96.0422 154.488 93.9082 150.393C92.7984 148.264 91.0814 145.068 91.0814 145.068C91.0814 145.068 91.0034 149.477 90.0771 151.764C88.203 156.39 85.9473 154.534 82.5445 156.2Z"
        fill="url(#campsite_3_linear)"
      />
      <path
        d="M90.4031 168.155C91.168 168.63 91.6626 169.607 91.7422 170.499C91.8922 172.18 89.77 174.174 87.9897 174.212C86.4652 174.245 85.4811 173.058 85.0239 171.751C84.4389 170.079 84.8941 167.393 85.8705 165.904C86.3783 165.13 88.7291 161.794 88.7291 161.794C88.7291 161.794 88.4618 164.991 88.7291 165.904L88.7303 165.908C89.0944 167.152 89.1631 167.386 90.4031 168.155Z"
        fill="url(#campsite_4_linear)"
      />
      <path
        d="M19.5001 110C47.0673 116.489 121 119.5 121 119.5L79.5001 62.4998C79.5001 62.4998 14.3704 61.3893 5.2638 89.4418C2.58939 97.6802 9.40525 107.624 19.5001 110Z"
        fill="url(#campsite_5_linear)"
        style={{ mixBlendMode: 'color' }}
      />
      <path
        d="M172.5 110.501C172.5 110.501 175.5 46.0001 160.5 24.9999C154.939 17.2142 144.229 20.1198 136 25.0011C106.5 42.4999 78 62.0011 78 62.0011L172.5 110.501Z"
        fill="url(#campsite_6_linear)"
        style={{ mixBlendMode: 'color' }}
      />
      <path
        d="M88.0314 24.5015L83.863 25.5015V32.7415H88.0314V24.5015Z"
        fill="#838383"
      />
      <path
        d="M86.0005 28.0015L53.0005 98.0015L73.0005 107.001L111 128.001L144.5 117.501L177 114.001L146 43.0015L113 40.0015L86.0005 28.0015Z"
        fill="url(#campsite_7_linear)"
      />
      <path
        d="M86.0005 28.0015L53.0005 98.0015L73.0005 107.001L111 128.001L144.5 117.501L177 114.001L146 43.0015L113 40.0015L86.0005 28.0015Z"
        fill="url(#campsite_8_radial)"
        style={{ mixBlendMode: 'multiply' }}
      />
      <path
        d="M111 128.001L146 43.0013L177 114.001L144.5 117.501L111 128.001Z"
        fill="#D2A584"
      />
      <path
        d="M111 128.001L146 43.0013L177 114.001L144.5 117.501L111 128.001Z"
        fill="url(#campsite_9_linear)"
        style={{ mixBlendMode: 'multiply' }}
      />
      <path d="M144 48.0013L142 118.291L144 118.001V48.0013Z" fill="#7B6657" />
      <path
        d="M148 42.0013L144 41.0013V122.001L148 121.001V42.0013Z"
        fill="#838383"
      />
      <defs>
        <radialGradient
          id="campsite_0_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(92.0889 169.787) rotate(90) scale(50.2575 63.8846)"
        >
          <stop stopColor="#D36B7E" />
          <stop offset="0.505208" stopColor="#724351" />
          <stop offset="0.505208" stopColor="#724351" />
          <stop offset="0.515625" stopColor="#724351" />
          <stop offset="1" stopColor="#28242F" stopOpacity="0" />
        </radialGradient>
        <linearGradient
          id="campsite_1_linear"
          x1="86.25"
          y1="170.05"
          x2="86.5"
          y2="191.05"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#714343" />
          <stop offset="1" stopColor="#714343" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="campsite_2_linear"
          x1="83.3626"
          y1="142.397"
          x2="91.7337"
          y2="188.525"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DE8282" />
          <stop offset="1" stopColor="#9A4101" />
        </linearGradient>
        <linearGradient
          id="campsite_3_linear"
          x1="93.0274"
          y1="146.844"
          x2="81.6083"
          y2="180.336"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC774" />
          <stop offset="1" stopColor="#9A1C01" />
        </linearGradient>
        <linearGradient
          id="campsite_4_linear"
          x1="86.3451"
          y1="164.576"
          x2="89.4364"
          y2="177.865"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F18888" />
          <stop offset="1" stopColor="#6F1E95" />
        </linearGradient>
        <linearGradient
          id="campsite_5_linear"
          x1="119.197"
          y1="56.0345"
          x2="21.7805"
          y2="142.796"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2C2636" />
          <stop offset="1" stopColor="#312531" stopOpacity="0.8" />
        </linearGradient>
        <linearGradient
          id="campsite_6_linear"
          x1="145"
          y1="86.5"
          x2="170"
          y2="11"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.234375" stopColor="#2D2738" />
          <stop offset="1" stopColor="#2A2533" stopOpacity="0.8" />
        </linearGradient>
        <linearGradient
          id="campsite_7_linear"
          x1="83.0001"
          y1="70.5"
          x2="-8.99988"
          y2="112.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E6B18C" />
          <stop offset="1" stopColor="#584C69" />
        </linearGradient>
        <radialGradient
          id="campsite_8_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(90.0001 179) rotate(-79.4104) scale(116.993 145.071)"
        >
          <stop stopColor="#F9216F" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </radialGradient>
        <linearGradient
          id="campsite_9_linear"
          x1="86.5"
          y1="180.5"
          x2="167"
          y2="69.4999"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F9216F" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  </div>
);

export default Background;
